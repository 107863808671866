import Vue from "vue";
import Router from "vue-router";
import axios from "axios";
import store from "./store";

Vue.use(Router);
let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      props: true,
      component: () => import("./layout/DefaultADV"),
      meta: {
        requiresAuth: true,
      },
      children: [
        ////////////////// Finanace-routes-Start ////////////////
        {
          path: "/PendingFund",
          name: "PendingFund",
          meta: {
            requiresAuth: true,
            isSuperAdmin: false,
            isFinance: true,

          },
          component: () => import("./Finance/raisedFundList"),
        },
        //////////////////// Finanace-end //////////////////////
        {
          path: "",
          name: "dashboard",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/Dashboard/Dashboard"),
        },

        {
          path: "/SplitDetaildView",
          name: "SplitDetaildView",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/UserList/splitDetailView"),
        },
        {
          path: "/CategoryList",
          name: "CategoryList",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/Category/category"),
        },
        {
          path: "/OrganisationList",
          name: "OrganisationList",
          meta: {
            requiresAuth: true,
            isSuperAdmin: true,
            isFinance: false,

          },
          component: () => import("./views/Organisation/organisation"),
        },
        {
          path: "/DetailedViewPage",
          name: "DetailedViewPage",
          meta: {
            requiresAuth: true,
            isSuperAdmin: true,
            isFinance: false,

          },
          component: () => import("./views/Organisation/DetailedViewPage"),
        },
        
        {
          path: "/OrganisationType",
          name: "OrganisationType",
          meta: {
            requiresAuth: true,
            isSuperAdmin: true,
            isFinance: false,

          },
          component: () => import("./views/OrganisationType/organisation"),
        },
        {
          path: "/Projects",
          name: "project",
          props:true,
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/projects/project"),
        },
        {
          path: "/projectView",
          name: "projectView",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/projects/singleProject"),
        },

        {
          path: "/projectSplitView",
          name: "projectSplitView",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/projects/viewtargetSplit"),
        },



        {
          path: "/volunteer",
          name: "volunteer",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/volunteerTitle/title"),
        },
        {
          path: "/Applications",
          name: "Applications",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/Applications/applications"),
        },
        {
          path: "/volunteerApplication",
          name: "volunteerApplication",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/volunteerApplication/applications"),
        },
        {
          path: "/viewApplication",
          name: "viewApplication",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/Applications/viewApplication"),
        },
        {
          path: "/viewVolunteerApplication",
          name: "viewVolunteerApplication",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/volunteerApplication/viewApplication"),
        },
        //Employee document
        {
          path: "/employeedocument",
          name: "employeedocument",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/EmployeeDocument/documents"),
        },
        {
          path: "/viewdocument",
          name: "viewdocument",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/EmployeeDocument/viewDoc"),
        },
        {
          path: "/employeelist",
          name: "employeelist",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/EmployeeList/list"),
        },
        {
          path: "/employeeview",
          name: "employeeview",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/EmployeeList/listView"),
        },

        {
          path: "/userList",
          name: "userList",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/UserList/list"),
        },

        {
          path: "/userView",
          name: "userView",
          meta: {
            requiresAuth: true,
          },
          component: () => import("./views/UserList/listView"),
        },

        {
          path: "/addProducts",
          name: "addProducts",
          component: () => import("./views/Products/addProduct"),
        },
        {
          path: "/products",
          name: "Products",
          component: () => import("./views/Products/products"),
        },
        {
          path: "/product/:id",
          name: "Product",
          component: () => import("./views/Products/product"),
          props: true,
        },



        {
          path: "/editProduct/:id",
          name: "EditProduct",
          component: () => import("./views/Products/editProduct"),
          props: true,
        },
        {
          path: "/aboutUs",
          name: "aboutUs",
          component: () => import("./views/About/about"),
        },

      ],
    },
    {
      path: "/",
      props: true,
      component: () => import("./layout/Authentication"),
      meta: {
        requiresAuth: false,
      },
      children: [

        {
          path: "login",
          name: "login",
          meta: {
            requiresAuth: false,
          },
          component: () => import("./views/Authentication/Login"),
        },
        {
          path: "signup",
          name: "signup",
          meta: {
            requiresAuth: false,
          },
          component: () => import("./views/Authentication/Signup"),
        },
      ],
    },

    {
      path: "/servererror",
      name: "servererror",
      props: true,
      component: () => import("./layout/500"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "*",
      name: "404pagenotfound",
      props: true,
      component: () => import("./layout/404"),
      meta: {
        requiresAuth: false,
      },
    },
  ],
  scrollBehavior() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  },
});
router.beforeEach((to, from, next) => {
  //new lines
  localStorage.setItem("PRE", from.path);
  // end  of newely added lines
  if (
    to.matched.some((route) => route.meta.requiresAuth == true) &&
    store.state.isLoggedIn == false
  ) {
    console.log(from);
    next({ name: "login", params: { lastPage: from } });
    return;
  }

  if (to.matched.some((route) => route.meta.isSuperAdmin == false) && store.state.userType == 'SuperAdmin') {
    next({ name: 'dashboard' });
    return;
  }
  if (to.matched.some((route) => route.meta.isFinance == false) && store.state.userType == 'Finance') {
    next({ name: 'dashboard' });
    return;
  }






  if (store.state.isLoggedIn == true) {
    axios({
      method: "GET",
      url: "admin/profile",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    })
      .then((response) => {
        if (!response.data.status) {
          store.commit("sessionOut", true);
          return;
        }
      })
      .catch((err) => {
        var msg = err;
        console.log(msg);
        store.commit("sessionOut", true);
      });
  }
  if (
    (to.name == "login" || to.name == "home") &&
    store.state.isLoggedIn == true
  ) {
    next({ name: "dashboard" });
    return;
  }
  next();
});

export default router;
